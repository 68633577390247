import MacronutrientTable from "../Tables/MacronutrientTable";

export default function MacronutrientRow({ weight, fat, final }) {
  return (
    <div className="row" >
        <div className="col col-md-6 offset-md-3">
          <h2 className="text-center">Macronutrient Split</h2>
          <MacronutrientTable {...{ weight, fat, final }} />
        </div>
      </div>
  )
}
