import CalculationTable from "../Tables/CalculationTable";

export default function CalculationRow({ gender, weight, height, age, activity, goal, bmr, tdee, final, GOAL_ADJUST_CALORIES }) {
  return (
    <div className="row" >
      <div className="col col-md-6 offset-md-3" >
        <h2 className="text-center">Calculations</h2>
        <CalculationTable
          {...{
            gender, weight, height, age,
            activity, goal, bmr, tdee,
            final, GOAL_ADJUST_CALORIES
          }}
        />
      </div>
    </div>
  )
}
