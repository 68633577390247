export default function GenderInput({ setGender }) {
  const handleGenderChange = (e) => {
    const val = e.target.value
    setGender(val)
  }

  return (
    <div className="input-group mb-3">
      <span className="input-group-text" >Gender</span>
      <select className="form-select" onChange={handleGenderChange} >
        <option value="m" selected >Male</option>
        <option value="f" >Female</option>
      </select>
    </div>
  )
}
