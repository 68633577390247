export default function WeightInput({ setWeight }) {
  const handleWeightChange = (e) => {
    const val = e.target.value
    setWeight(val)
  }

  return (
    <div className="input-group mb-3">
      <span className="input-group-text" >Weight (kg)</span>
      <input type="number" min={0} className="form-control" placeholder={104} onChange={handleWeightChange} />
    </div>
  )
}
