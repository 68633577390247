export default function AgeInput({ setAge }) {
  const handleAgeChange = (e) => {
    const val = e.target.value
    setAge(val)
  }

  return (
    <div className="input-group mb-3">
      <span className="input-group-text" >Age</span>
      <input type="number" min={0} className="form-control" placeholder={24} onChange={handleAgeChange} />
    </div>
  )
}
