export default function HeightInput({ setHeight }) {
  const handleHeightChange = (e) => {
    const val = e.target.value
    setHeight(val)
  }

  return (
    <div className="input-group mb-3">
      <span className="input-group-text" >Height (cm)</span>
      <input type="number" min={0} className="form-control" placeholder={193} onChange={handleHeightChange} />
    </div>
  )
}
