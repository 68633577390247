import ActivityInput from "../Inputs/ActivityInput";
import AgeInput from "../Inputs/AgeInput";
import FatInput from "../Inputs/FatInput";
import GenderInput from "../Inputs/GenderInput";
import GoalInput from "../Inputs/GoalInput";
import HeightInput from "../Inputs/HeightInput";
import WeightInput from "../Inputs/WeightInput";

export default function InputsRow({
  setGender,
  setWeight,
  setHeight,
  setAge,
  setActivity,
  setFat,
  setGoal,
  GOAL_ADJUST_CALORIES
}) {
  return (
    <div className="row">
        <div className="col">
          <h2 className="text-center mb-3" >Inputs</h2>
          <form>
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <GenderInput setGender={setGender} />
              </div>
              <div className="col-sm-12 col-md-3">
                <WeightInput setWeight={setWeight} />
              </div>
              <div className="col-sm-12 col-md-3">
                <HeightInput setHeight={setHeight} />
              </div>
              <div className="col-sm-12 col-md-3">
                <AgeInput setAge={setAge} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <ActivityInput setActivity={setActivity} />
              </div>
              <div className="col-sm-12 col-md-4">
                <FatInput setFat={setFat} />
              </div>
              <div className="col-sm-12 col-md-4">
                <GoalInput setGoal={setGoal} GOAL_ADJUST_CALORIES={GOAL_ADJUST_CALORIES}  />
              </div>
            </div>
          </form>
        </div>
      </div>
  )
}
