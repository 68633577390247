import { useEffect, useState } from "react";
import CalculationRow from "./components/MacroCalculator/Rows/CalculationRow";
import InputsRow from "./components/MacroCalculator/Rows/InputsRow";
import MacronutrientRow from "./components/MacroCalculator/Rows/MacronutrientRow";
import ReferenceRow from "./components/MacroCalculator/Rows/ReferenceRow";

export default function MacroCalculator() {
  const [gender, setGender] = useState("m")
  const [weight, setWeight] = useState(104)
  const [height, setHeight] = useState(193)
  const [age, setAge] = useState(24)
  const [activity, setActivity] = useState(1.55)
  const [fat, setFat] = useState(30)
  const [goal, setGoal] = useState(0)

  const [bmr, setBmr] = useState(0)
  const [tdee, setTdee] = useState(0)
  const [final, setFinal] = useState(0)

  useEffect(() => {
    const new_bmr = (10 * weight) + (6.25 * height) - (5 * age) + (gender == "m" ? 5 : -161)

    setBmr(new_bmr)
    setTdee(new_bmr * activity)
    setFinal((new_bmr * activity) + parseInt(goal))
  }, [gender, weight, height, age, activity, goal])

  const GOAL_ADJUST_CALORIES = 200

  return (
    <div className="container">
      <h1 className="text-center mt-3" >Macro Calculator</h1>
      <hr />
      <InputsRow
        {...{
          setGender, setWeight, setHeight, setAge,
          setActivity, setFat, setGoal, GOAL_ADJUST_CALORIES
        }}
      />
      <hr />
      <CalculationRow
        {...{
          gender, weight, height, age,
          activity, goal, bmr, tdee,
          final, GOAL_ADJUST_CALORIES
        }}
      />
      <hr />
      <MacronutrientRow {...{ weight, fat, final }} />
      <hr />
      <ReferenceRow {...{ GOAL_ADJUST_CALORIES }} />
      <hr />
      <p className="text-muted text-end mb-3 pe-3">&copy; {(new Date()).getFullYear()} Training With Intention LLC</p>
    </div>
  );
}
