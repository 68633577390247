export default function ActivityInput({ setActivity }) {
  const handleActivityChange = (e) => {
    const val = e.target.value
    setActivity(val)
  }

  return (
    <div className="input-group mb-3">
      <span className="input-group-text" >Activity Level</span>
      <select className="form-select" onChange={handleActivityChange}>
        <option value={1.2}>Sedentary (BMR * 1.2)</option>
        <option value={1.375} >Lightly Active (BMR * 1.375)</option>
        <option value={1.55} selected >Moderately Active (BMR * 1.55)</option>
        <option value={1.725} >Very Active (BMR * 1.725)</option>
        <option value={1.9} >Extra Active (BMR * 1.9)</option>
      </select>
    </div>
  )
}
