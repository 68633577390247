export default function ReferenceRow({ GOAL_ADJUST_CALORIES }) {
  return (
    <div className="row">
      <div className="col">
        <h2 className="text-center" >Reference</h2>
        <h6 className="text-center" >Male BMR Calculation: (10 * weight in kg) + (6.25 * height in cm) - (5 * age) + 5</h6>
        <h6 className="text-center" >Female BMR Calculation: (10 * weight in kg) + (6.25 * height in cm) - (5 * age) - 161</h6>
        <h6 className="text-center" >TDEE Calculation: BMR * Activity Level Multiplier</h6>
        <h6 className="text-center" >Final Calculation: TDEE +/- Goal Calorie Adjustment ({GOAL_ADJUST_CALORIES} kcal)</h6>
        <br />
        <h6 className="text-center" >Protein: 1.2 * weight in kg (4 kcal / g)</h6>
        <h6 className="text-center" >Fat: Fat % * total calorie (9 kcal / g)</h6>
        <h6 className="text-center" >Carbs: remaining calories (4 kcal / g)</h6>
      </div>
    </div>
  )
}