export default function GoalInput({ setGoal, GOAL_ADJUST_CALORIES }) {
  const handleGoalChange = (e) => {
    const val = e.target.value
    setGoal(val)
  }

  return (
    <div className="input-group mb-3">
      <span className="input-group-text" >Body Goal</span>
      <select className="form-select" onChange={handleGoalChange} >
        <option value={0} selected >Maintain</option>
        <option value={GOAL_ADJUST_CALORIES} >Gain</option>
        <option value={-GOAL_ADJUST_CALORIES} >Lose</option>
      </select>
    </div>
  )
}