export default function MacronutrientTable({ weight, fat, final }) {
  return (
    <table className="table table-striped border text-center">
      <thead>
        <tr>
          <th></th>
          <th>Calculation</th>
          <th>Calories</th>
          <th>Grams</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" className="text-start ps-4">Protein</th>
          <td className="border-end">1.2 * {weight}</td>
          <td>{Math.ceil(1.2 * weight)} kcal</td>
          <td>{Math.ceil(Math.ceil(1.2 * weight) / 4)} g</td>
        </tr>
        <tr>
          <th scope="row" className="text-start ps-4">Fat</th>
          <td className="border-end">{fat}% * {Math.ceil(final)}</td>
          <td>{Math.ceil(fat / 100 * final)} kcal</td>
          <td>{Math.ceil(Math.ceil(fat / 100 * final) / 9)} g</td>
        </tr>
        <tr>
          <th scope="row" className="text-start ps-4">Carbs</th>
          <td className="border-end">{Math.ceil(final)} - {Math.ceil(1.2 * weight)} - {Math.ceil(fat / 100 * final)} </td>
          <td>{Math.ceil(final) - (Math.ceil(1.2 * weight)) - (Math.ceil(fat / 100 * final))} kcal</td>
          <td>{Math.ceil((Math.ceil(final) - (Math.ceil(1.2 * weight)) - (Math.ceil(fat / 100 * final))) / 4)} g</td>
        </tr>
      </tbody>
    </table>
  )
}
