export default function FatInput({ setFat }) {
  const handleFatChange = (e) => {
    const val = e.target.value
    setFat(val)
  }

  return (
    <div className="input-group mb-3">
      <span className="input-group-text" >Fat % In Split</span>
      <input type="number" min={20} max={50} className="form-control" placeholder={30} onChange={handleFatChange} />
    </div>
  )
}
