export default function CalculationTable({ gender, weight, height, age, activity, goal, bmr, tdee, final, GOAL_ADJUST_CALORIES }) {
  return (
    <table className="table table-striped border text-center">
      <thead>
        <tr>
          <th></th>
          <th>Calculation</th>
          <th>Calories</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" className="text-start ps-4">BMR</th>
          <td className="border-end">(10 * {weight}) + (6.25 * {height}) - (5 * {age}) {gender == "m" ? "+ 5" : "- 161"}</td>
          <td>{Math.ceil(bmr)} kcal</td>
        </tr>
        <tr>
          <th scope="row" className="text-start ps-4">TDEE</th>
          <td className="border-end">{Math.ceil(bmr)} * {activity}</td>
          <td>{Math.ceil(tdee)} kcal</td>
        </tr>
        <tr>
          <th scope="row" className="text-start ps-4">Final</th>
          <td className="border-end">{Math.ceil(tdee)}{goal == 0 ? " + 0" : goal > 0 ? ` + ${GOAL_ADJUST_CALORIES}` : ` - ${GOAL_ADJUST_CALORIES}`}</td>
          <td>{Math.ceil(final)} kcal</td>
        </tr>
      </tbody>
    </table>
  )
}
